import React, { useState } from "react";
import Button from "../../components/button/button";
import { submit } from "../../utils/submit";
import ReCAPTCHA from "react-google-recaptcha"; 

import "./jobForm.scss";
import {navigate} from "gatsby";
import InputFile from "../../shared/ui/inputFile";
import InputTelephone from "../../shared/ui/inputTelephone";
import InputMail from "../../shared/ui/inputMail";
import FormError from "../formError";
import ChampsObligatoiresMention from "../champsObligatoiresMention";

export default function JobForm({ reference, setReference, infoForm, setInfoForm }) {

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (e) => {
    if(e.target.id === "cv" || e.target.id === "motivation"){
      setInfoForm((prevInfo) => ({
        ...prevInfo,
        [e.target.name]: e.target.files[0],
      }));
    }else{
      setInfoForm((prevInfo) => ({
        ...prevInfo,
        [e.target.name]: e.target.value.trim(),
      }));
    }
  };

  const handleChangeReference = (e) => {
    setReference(e.target.value);
    handleChange(e);
  };

  const handleError = () => {
    setFormError(true);
    document.getElementById("form-error")?.scrollIntoView();
  }

  return (
    <section className="jobForm__section" id="jobForm">
      <p className="jobForm__intro">
        Pour nous faire parvenir <strong>votre candidature</strong>, merci de
        nous indiquer votre...
      </p>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          if(!isLoading && isCaptchaValid) {
            setIsLoading(true);
            submit(infoForm).then((response) => {
              if(response?.ok) {
                navigate("/merci-pour-votre-demande", {
                  state: {
                    about: 'candidature'
                  }
                });
              } else {
                handleError();
              }
              setIsLoading(false);
            }, () => {
              handleError();
              setIsLoading(false);
            });
          }
        }}
      >
        {formError && <FormError />}
        <div>
          <input
            type="text"
            name="nom"
            placeholder="nom *"
            onChange={handleChange}
            required
          />
          <span className="form__comma">,</span>
          <input
            type="text"
            name="prenom"
            placeholder="prénom *"
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">votre</label>
          <InputMail
              inputPlaceholder="adresse email *"
              inputName="email"
              inputRequired={true}
              inputHandleChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="telephone">votre</label>
          <InputTelephone
              inputPlaceholder="numéro de téléphone *"
              inputName="telephone"
              inputId="telephone"
              inputHandleChange={handleChange}
              inputRequired={true}
          />
        </div>
        <div>
        <input
          type="text"
          name="adresse"
          placeholder="adresse *"
          onChange={handleChange}
          required
        />
        </div>
        <div>
          <label htmlFor="code-postal">ainsi que votre</label>
          <input
            type="number"
            placeholder="code postal *"
            max={99999}
            name="code-postal"
            onChange={handleChange}
            required
          />
          <span className="form__comma">,</span>
          <input
            type="text"
            name="ville"
            placeholder="ville *"
            onChange={handleChange}
            required
          />
        </div>
        <span className="jobForm__question">
          Vous postulez à une offre en particulier&nbsp;?
        </span>
        <div>
          <label htmlFor="reference">Quelle est sa</label>
          <input
            className="input-no-flex-grow"
            type="text"
            name="reference"
            value={reference}
            placeholder="référence"
            onChange={handleChangeReference}
          />
          <span className="form__comma">?</span>
        </div>
        <div>
          <span>Téléchargez votre CV </span>
          <InputFile inputName="cv" inputId="cv" inputRequired={true} handleChange={handleChange} />
        </div>
        <div>
          <span>et votre lettre de motivation</span>
          <InputFile inputName="motivation" inputId="motivation" inputRequired={true} handleChange={handleChange} />
        </div>
        <div>
          <span>
            Vous avez un profil LinkedIn&nbsp;? Partagez-nous le lien&nbsp;:
          </span>
          <input type="text" name="profil" id="profil" placeholder="le lien vers votre profil" onChange={handleChange} />
        </div>
        <ChampsObligatoiresMention showFinaliteTraitementDonnees={true} />
        <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
            onChange={(val)=>{setIsCaptchaValid(!!val)}}
          />
        <Button type="submit" className="jobForm__btn" text="Envoyer" isDisabled={!isCaptchaValid} isLoading={isLoading} />
      </form>
    </section>
  );
}
