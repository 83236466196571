import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import Button from "../../components/button/button";
import Faq from "../../components/faq/faq";
import HeaderLight from "../../components/header/headerLight/headerLight";

import "./style.scss";

import imgSection from "../../images/offres-emploi/photo-section.webp";
import JobForm from "../../components/jobForm/jobForm";
import { useState } from "react";
import axios from "axios";

const OffresEmploi = ({ location }) => {
  const [infoForm, setInfoForm] = useState({"code":"job-form"});
  const [offres, setOffres] = useState([]);

  useEffect(() => {
    axios.get("https://www.talentdetection.com/api/v1/share/QKJOWqDgP6Ay9nD8XaSQvw==")
      .then((res) => {
        setOffres(res.data.Results);
      });
  }, [setOffres]);

  return (
    <>
      <Helmet htmlAttributes={{
          class: "no-scrolling"
      }}>
        <body className="offres-emploi" />
      </Helmet>
      <Layout>
        <SEO title="Offres d'emploi" description="Découvrez nos offres d'emploi. Nous recrutez des professionnels en accord avec nos engagements : proximité, écoute et expertise sont au cœur de nos échanges avec nos sociétaires."/>
        <HeaderLight
          title={
            <>
              Nos offres <br/> <strong>d'emploi</strong>
            </>
          }
          location={location}
          crumbLabel="Offres emploi"
        />
        <section className="offres-emploi__offres">
          <p className="offres-emploi__offres__description">L’Auxiliaire, mutuelle d’assurance, est au service des hommes et des femmes du BTP depuis 1863. Nos collaborateurs sont reconnus pour leur expertise et leur sens du service client. Nous offrons à nos clients-sociétaires un service de proximité et une approche individuelle des besoins. Nous nous attachons à accorder la même attention à nos salariés. Nous sommes pleinement mobilisés pour répondre aux enjeux d’aujourd’hui et de demain ; l’innovation est au cœur de notre stratégie, interne comme externe.</p>
          {offres.map((offre, i) => {
            return (
              <Faq question={offre.NameAdvertise} key={i} number={i}>
                <div className="offres-emploi__offre-resume" dangerouslySetInnerHTML={{ __html: offre.DescriptionFormatted.replace(/<br[\s]*\/?>/g, "") }}></div>
                <div className="offres-emploi__offre-profil" dangerouslySetInnerHTML={{ __html: offre.ProfileFormatted.replace(/<br[\s]*\/?>/g, "") }}></div>
                <div className="offres-emploi__offre-footer">
                  <Button
                    to={offre.ApplyLinkPage}
                    target="_blank"
                    text="Postuler"
                  />
                </div>
              </Faq>
            );
          })}
        </section>

        <section className="offres-emploi__other">
          <div className="offres-emploi__text">
            <h2 className="offres-emploi__other-heading">
              Aucune offre ne correspond à <strong>votre profil</strong>&nbsp;?
            </h2>
            <p>Postulez via le formulaire ci-dessous&nbsp;!</p>
          </div>
          <img src={imgSection} alt="illustration" />
        </section>
        <JobForm infoForm={infoForm} setInfoForm={setInfoForm}/>
      </Layout>
    </>
  );
};

export default OffresEmploi;
